import React, { useState, useEffect } from "react";
import http from '../_api/http';

const AcademicInfra = () => {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;

  useEffect(() => {
    http.get('api/imageupload/ApiImageUploadList?image_subcategory_id=15&section=ACADEMIC INFRA').then((res) => {
      if (res.data.status === 200) {
        setImage(res.data.image);
        setLoading(false);
      }
    });
  }, []);
  return (
    <section className="gallery-one">
      <div className="container">
        <div className="row">
          {loading
            ? <h4>View AcandemicInfra Images Loading...</h4>
            :
            <>
              {image.map((item, i) =>
                <div className="col-lg-3 col-md-4" key={i}>
                  <div className="gallery-one__single">
                    <img src={PUBLIC_URL + "uploadimage/" + item.image} alt="" />
                    <div className="gallary-one__content">
                      <p className="gallary-one__gallary">
                        {item.image_title}
                      </p>
                    </div>
                    <a className="gallery-one__popup img-popup">
                      <i className="kipso-icon-plus-symbol"></i>
                    </a>
                  </div>
                </div>
              )}

            </>
          }
        </div>
      </div>
    </section>
  );
};

export default AcademicInfra;
