import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import AcademicInfra from '../components/AcademicInfra';
import Topbar from "../components/Topbar";

const AcandmyInfraPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | InfraStructure">
            <Topbar />
            <NavOne />
            <PageHeader title="Academic Infra" />
            <AcademicInfra />
            <Footer />
        </Layout>
    );
};

export default AcandmyInfraPage;
